import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { parseEmbedlySize } from '../helpers/parseEmbedlySize';
import { parseLinkCardData } from '../iframe/helpers';
import { EmbedExtensionName } from '../types';
import EmbedCardNode from './EmbedCardNode';

export default Node.create({
  name: EmbedExtensionName.EMBED_CARD,
  group: 'block',
  atom: true,
  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      size: { default: 'large' },
      contentType: { default: 'media' },
      contentUrl: { default: null },
      imgUrl: { default: null },
      title: { default: null },
      description: { default: null },
      extensionName: { default: EmbedExtensionName.EMBED_CARD },
      altViewAttrs: { default: null },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.link-card',
        priority: 100,
        getAttrs: (node) => {
          if (typeof node === 'object' && node instanceof HTMLElement) {
            const parentDiv = node.closest('div[data-original-result]');

            if (parentDiv) {
              const originalResultData = parentDiv.getAttribute('data-original-result');
              const originalResultUrl = parentDiv.getAttribute('data-original-url');

              if (!originalResultData || !originalResultUrl) return null;

              const resultData = parseLinkCardData(originalResultData, originalResultUrl);

              const { type } = resultData;

              let altViewAttrs: { [key: string]: string } | undefined;

              if (type === 'video' || type === 'rich') {
                const iframeElement = new DOMParser()
                  .parseFromString(resultData.html, 'text/html')
                  .querySelector('iframe');

                if (iframeElement) {
                  altViewAttrs = Array.from(iframeElement.attributes).reduce((obj, attr) => {
                    obj[attr.name] = attr.value;
                    return obj;
                  }, {} as { [key: string]: string });
                }
              }

              return {
                contentUrl: resultData.url,
                imgUrl: resultData.thumbnail_url,
                title: resultData.title,
                description: resultData.description,
                altViewAttrs,
                size: parseEmbedlySize(Array.from(parentDiv.classList)),
              };
            }
          }

          return null;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        class: 'link-card',
        ...HTMLAttributes,
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmbedCardNode);
  },
});
