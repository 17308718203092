import FileHandler from '@tiptap-pro/extension-file-handler';
import { Editor } from '@tiptap/core';
import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { axiosApi } from '../../../../../redux/services/axiosService';
import { store } from '../../../../../redux/stores/store';
import { flash } from '../../../../FlashNotificationContext';
import {
  MAX_FILE_SIZE_MB,
  MAX_FILE_UPLOAD_BATCH_SIZE,
  SUPPORTED_UPLOAD_TYPES_BY_CONTENT_TYPE,
} from '../../shared/constants/editor';
import { EmbedExtensionName } from '../types';
import ErrorMessage from './ErrorMessage';

const t = initTranslations('editor');

// { 'image/png': 'image', 'application/pdf': 'file', ... }
const CONTENT_TYPE_BY_MINE_TYPE: { [key: string]: string } = Object.entries(
  SUPPORTED_UPLOAD_TYPES_BY_CONTENT_TYPE
).reduce((result, [contentType, mimeTypes]) => {
  return { ...result, ...Object.fromEntries(mimeTypes.map((mt) => [mt, contentType])) };
}, {});

const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const uploadResult = await store.dispatch(axiosApi.endpoints.postStepImage.initiate(formData));
  if (!('data' in uploadResult && uploadResult.data?.link)) {
    return;
  }

  return {
    type: 'image',
    attrs: {
      src: uploadResult.data.link,
    },
  };
};

const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const uploadResult = await store.dispatch(
    axiosApi.endpoints.postStepAttachment.initiate(formData)
  );
  if (!('data' in uploadResult && uploadResult.data?.fileLink)) {
    return;
  }

  return {
    type: EmbedExtensionName.FILE,
    attrs: {
      contentUrl: uploadResult.data.fileLink,
      fileName: uploadResult.data.fileName,
    },
  };
};

const handleFiles = async (currentEditor: Editor, files: File[], position: number) => {
  if (files.length > 10) {
    flash(
      'warning',
      <ErrorMessage
        body={t('file.error.file_count_body', { fileCountLimit: MAX_FILE_UPLOAD_BATCH_SIZE })}
        title={t('file.error.file_count_title')}
      />,
      {
        hasCustomMessageStructure: true,
      }
    );
    return;
  }

  const filesExceedingSizeLimit = [];
  const filesFailingToUpload = [];
  const filesWithUnsupportedFileType = [];

  files = files.filter((file) => {
    if (file.size >= MAX_FILE_SIZE_MB * 1024 * 1024) {
      filesExceedingSizeLimit.push(file);
      return false;
    }
    return true;
  });

  if (filesExceedingSizeLimit.length) {
    flash('warning', t('file.error.file_size', { fileSizeMb: MAX_FILE_SIZE_MB }));
  }

  for (const file of files) {
    const contentType = CONTENT_TYPE_BY_MINE_TYPE[file.type];
    let newContent = null;

    if (contentType === 'image') {
      newContent = await uploadImage(file);
    } else if (contentType === 'file') {
      newContent = await uploadFile(file);
    } else {
      filesWithUnsupportedFileType.push(file);
      continue;
    }

    if (newContent) {
      currentEditor.chain().insertContentAt(position, newContent).focus().run();
    } else {
      filesFailingToUpload.push(file);
    }
  }

  if (filesFailingToUpload.length) {
    flash('error', t('file.error.generic'));
  }

  if (filesWithUnsupportedFileType.length) {
    flash('warn', t('file.error.file_type'));
  }
};

const EditorFileHandler = FileHandler.configure({
  allowedMimeTypes: Object.values(SUPPORTED_UPLOAD_TYPES_BY_CONTENT_TYPE).flat(),
  onDrop: async (currentEditor, files, pos) => {
    await handleFiles(currentEditor, files, pos);
    return true;
  },
  onPaste: async (currentEditor, files, pastedContent) => {
    if (pastedContent && !pastedContent.includes('data:image/')) {
      return false;
    }

    await handleFiles(currentEditor, files, currentEditor.state.selection.anchor);

    return true;
  },
});

export default EditorFileHandler;
