import React from 'react';

import { ErrorText, ErrorTitle } from './styles';

type ErrorMessageProps = {
  title: string;
  body: string;
};

const ErrorMessage = ({ title, body }: ErrorMessageProps) => {
  return (
    <>
      <ErrorTitle>{title}</ErrorTitle>
      <ErrorText>{body}</ErrorText>
    </>
  );
};

export default ErrorMessage;
