import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { EmbedExtensionName } from '../types';
import { parseIframeData } from './helpers';
import IframeResizeComponent from './IframeResizeComponent';

enum IframePriority {
  GENERAL = 100,
  EMBEDLY = 200,
}

interface IframeOptions {
  allowFullscreen: boolean;
}

export default Node.create<IframeOptions>({
  name: EmbedExtensionName.IFRAME,
  group: 'block',
  atom: true,
  selectable: true,

  addOptions() {
    return {
      allowFullscreen: true,
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: null,
        parseHTML: (element: HTMLIFrameElement) => {
          return element.width;
        },
      },
      height: {
        default: null,
        parseHTML: (element: HTMLIFrameElement) => {
          return element.height;
        },
      },
      scrolling: {
        default: false,
        parseHTML: (element: HTMLIFrameElement) => {
          return element.getAttribute('scrolling') === 'yes';
        },
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => this.options.allowFullscreen,
      },
      extensionName: {
        default: EmbedExtensionName.IFRAME,
      },
      size: {
        default: 'large',
      },
      contentUrl: { default: null },
      altViewAttrs: { default: null },
      isCapture: {
        default: false,
        parseHTML: (element: HTMLIFrameElement) => {
          return element.className.includes('trainual-capture-iframe');
        },
      },
      className: {
        default: null,
        parseHTML: (element: HTMLIFrameElement) => element.className || null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
        priority: IframePriority.GENERAL,
      },
      {
        tag: 'iframe.embedly-embed',
        priority: IframePriority.EMBEDLY,
        getAttrs: (node) => {
          if (typeof node === 'object' && node instanceof HTMLElement) {
            const parentDiv = node.closest('div[data-original-result]');

            if (parentDiv) {
              const originalResultData = parentDiv.getAttribute('data-original-result');

              if (!originalResultData) return null;

              return parseIframeData(parentDiv, originalResultData);
            }
          }

          return null;
        },
      },
      {
        tag: 'span.fr-video iframe',
        priority: IframePriority.GENERAL,
        getAttrs: (node) => {
          if (typeof node === 'object' && node instanceof HTMLElement) {
            return {
              contentUrl: node.getAttribute('src'),
              height: node.getAttribute('height'),
              width: node.getAttribute('width'),
              size: 'large',
            };
          }

          return null;
        },
      },
      {
        tag: 'iframe.trainual-capture-iframe',
        priority: IframePriority.GENERAL,
        getAttrs: (node) => {
          if (typeof node === 'object' && node instanceof HTMLElement) {
            return {
              contentUrl: node.getAttribute('src'),
              height: node.getAttribute('height'),
              width: node.getAttribute('width'),
              size: 'large',
            };
          }

          return null;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const classNames = HTMLAttributes.className ? HTMLAttributes.className.split(' ') : [];

    if (HTMLAttributes.isCapture) {
      // This is necessary to ensure that the iframe is styled correctly when copy/pasting captures in the editor
      // the tag above is looking for the trainual-capture-iframe class but prior to PR: https://github.com/trainual/trainual/pull/18351
      // the class was not being added to the iframe so this is needed to ensure captures
      // that were added before the PR are styled correctly when copy/pasted
      classNames.push('trainual-capture-iframe');
    }

    return ['iframe', { ...HTMLAttributes, class: classNames }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(IframeResizeComponent);
  },
});
