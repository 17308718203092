import { mergeAttributes } from '@tiptap/core';
import TiptapHorizontalRule from '@tiptap/extension-horizontal-rule';

const HorizontalRule = TiptapHorizontalRule.extend({
  renderHTML() {
    return this.editor?.isEditable
      ? ['div', mergeAttributes(this.options.HTMLAttributes, { 'data-type': this.name }), ['hr']]
      : ['hr'];
  },
});

export default HorizontalRule;
