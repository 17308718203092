import { Node, ReactNodeViewRenderer } from '@tiptap/react';

import { FA_BRAND_ICONS } from '../../../../../lib/font_libraries';
import FontAwesomeView from './FontAwesomeView';

export default Node.create({
  name: 'fontAwesome',
  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      icon: {
        default: null,
      },
      weight: {
        default: 'solid',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'i.fa',
        priority: 100,
        getAttrs: (dom: HTMLElement) => {
          const classList = dom.getAttribute('class');
          const match = /fa-([\w-]+)/.exec(classList || '');
          const iconName = match ? match[1] : null;
          let weight = null;

          if (classList?.includes('brands')) {
            weight = 'brands';
          } else if (classList?.includes('solid')) {
            weight = 'solid';
          } else {
            weight = FA_BRAND_ICONS.find((icon) => icon.iconName === iconName) ? 'brands' : 'solid';
          }

          return { icon: iconName, weight };
        },
      },
    ];
  },

  renderHTML: (props) => {
    const { node } = props;
    const attrs = node.attrs;

    return ['i', { class: `fa fa-${attrs.weight} fa-${attrs.icon}` }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(FontAwesomeView);
  },
});
