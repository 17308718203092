import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import styled from 'styled-components';

import { EditorEmbedOptionsProvider } from '../../../../../contexts/EditorEmbedOptionsContext';
import EmbedCard from '../../components/EmbedCard/EmbedCard';
import { CustomNodeSelectedState } from '../../shared/styles';
import FileDownloadNode from '../file_download/FileDownloadNode';
import { EmbedCardAttributes, FileAttributes } from '../types';

const StyledNodeViewWrapper = styled(NodeViewWrapper)`
  width: fit-content;
  margin: 0 auto;
`;

type Props = {
  deleteNode: NodeViewProps['deleteNode'];
  editor: NodeViewProps['editor'];
  node: { attrs: EmbedCardAttributes };
  updateAttributes: NodeViewProps['updateAttributes'];
  selected: boolean;
};

const EmbedCardNode = ({ deleteNode, editor, updateAttributes, node, selected }: Props) => {
  const { contentType, contentUrl, size, altViewAttrs, title, ...otherProps } = node.attrs;
  const isSelected = editor.isEditable && selected;

  const contentTypeProps =
    contentType === 'media' ? { contentType, ...otherProps } : { contentType };

  // Handle scenario for existing embed cards that were created before the FileDownload component was added
  if (contentType === 'file' && title) {
    const attrs: FileAttributes = { contentUrl, fileName: title };
    const node = { attrs };

    return <FileDownloadNode editor={editor} node={node} selected={selected} />;
  }

  return (
    <StyledNodeViewWrapper draggable>
      <EditorEmbedOptionsProvider
        currentAttributes={node.attrs}
        deleteNode={deleteNode}
        editor={editor}
        updateAttributes={updateAttributes}
      >
        <CustomNodeSelectedState selected={isSelected}>
          <EmbedCard
            canFrameView={!!altViewAttrs}
            contentUrl={contentUrl}
            isEditable={editor.isEditable}
            size={size}
            title={title}
            {...contentTypeProps}
          />
        </CustomNodeSelectedState>
      </EditorEmbedOptionsProvider>
    </StyledNodeViewWrapper>
  );
};

export default EmbedCardNode;
