import { IconName } from '@fortawesome/fontawesome-svg-core';
import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';

import Icon from '../../../../design_system/display/icons/Icon';
import { IconWeight } from '../../../../design_system/display/icons/Icon/IconTypes';

type Props = {
  node: { attrs: { icon: IconName; weight: IconWeight } };
};

const FontAwesomeView = ({ node }: Props) => {
  let iconName = node.attrs.icon;

  if (iconName.endsWith('-o')) {
    iconName = iconName.replace(/-o$/, '') as IconName;
  }

  const weight = node.attrs.weight;

  return (
    <NodeViewWrapper as='span'>
      <Icon className={`fa fa-${weight} fa-${iconName}`} name={iconName} weight={weight} />
    </NodeViewWrapper>
  );
};

export default FontAwesomeView;
