import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';

import { fontMd1 } from '../../../../styled/TypeSystem';

const Item = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
  margin: 0;
  width: 100%;
  background: transparent;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid transparent;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerSm3}`};

  &.is-selected {
    border-color: ${({ theme: { vars } }) => vars.borderSurface2};
  }

  img {
    aspect-ratio: 1;
    height: ${({ theme: { constants } }) => constants.height2xs};
  }
`;

const Items = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerSm1};
  position: relative;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(0, 0, 0, 0.1);
  ${fontMd1};
`;

interface EmojiItem {
  name: string;
  fallbackImage?: string;
  emoji: string;
}

interface EmojiListProps {
  items: EmojiItem[];
  command: (command: { name: string }) => void;
}

export interface EmojiListHandle {
  onKeyDown: (x: { event: KeyboardEvent }) => boolean;
}

const ForwardFunction: ForwardRefRenderFunction<EmojiListHandle, EmojiListProps> = (props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = useCallback(
    (index: number) => {
      const item = props.items[index];

      if (item) {
        props.command({ name: item.name });
      }
    },
    [props]
  );

  const upHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  }, [selectedIndex, props.items.length]);

  const downHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  }, [selectedIndex, props.items.length]);

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex);
  }, [selectedIndex, selectItem]);

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onKeyDown: (x) => {
          if (x.event.key === 'ArrowUp') {
            upHandler();
            return true;
          }

          if (x.event.key === 'ArrowDown') {
            downHandler();
            return true;
          }

          if (x.event.key === 'Enter') {
            enterHandler();
            return true;
          }

          return false;
        },
      };
    },
    [upHandler, downHandler, enterHandler]
  );

  return (
    <Items className='items'>
      {props.items.map((item, index) => (
        <Item
          className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
          key={index}
          onClick={() => selectItem(index)}
        >
          {item.fallbackImage ? <img alt='emoji' src={item.fallbackImage} /> : item.emoji}:
          {item.name}:
        </Item>
      ))}
    </Items>
  );
};

const EmojiList = forwardRef(ForwardFunction);

export default EmojiList;
