import { EmbedlyResponse } from '../../../../../types/EmbedlyResponse';
import { getIframeAttributesFromEmbedlyResponse } from '../../lib/getIframeAttributesFromEmbedlyResponse';
import { prependHttps } from '../../shared/helpers';
import { parseEmbedlySize } from '../helpers/parseEmbedlySize';

const KEY_PATTERN = /(?:{|,)\s*(\w+)\s*:/g;

const addQuotesToKeys = (jsonStr: string): string => {
  return jsonStr.replace(KEY_PATTERN, (match: string, p1: string) => {
    const prefix = match.charAt(0);

    return `${prefix} "${p1}":`;
  });
};

const extractValue = (key: string, data: string) => {
  const keyIndex = data.indexOf(key);
  if (keyIndex === -1) {
    return null;
  }
  const startIndex = data.indexOf(':', keyIndex) + 1;
  const endIndex =
    data.indexOf(',', startIndex) !== -1
      ? data.indexOf(',', startIndex)
      : data.indexOf('}', startIndex);

  return data
    .substring(startIndex, endIndex)
    .trim()
    .replace(/^"(.*)"$/, '$1');
};

const extractUrl = (key: string, jsonStr: string) => {
  const urlPattern = `"${key}":(https://[^,]+)`;
  const urlMatch = jsonStr.match(urlPattern);

  return urlMatch ? urlMatch[1] : null;
};

const parseEmbedlyDataResponse = (parentDiv: Element, originalResultData: string) => {
  const resultData: EmbedlyResponse = JSON.parse(originalResultData);

  const { contentUrl, altViewAttrs } = getIframeAttributesFromEmbedlyResponse(resultData);

  return {
    contentUrl,
    altViewAttrs,
    size: parseEmbedlySize(Array.from(parentDiv.classList)),
  };
};

const parseMalformedEmbedlyDataResponse = (parentDiv: Element, originalResultData: string) => {
  const stringifyKeys = addQuotesToKeys(originalResultData);
  const contentUrl = extractUrl('url', stringifyKeys);

  const altViewAttrs = {
    contentUrl,
    imgUrl: extractValue('thumbnail_url', stringifyKeys),
    title: extractValue('title', stringifyKeys),
    description: extractValue('description', stringifyKeys),
  };

  return {
    contentUrl,
    altViewAttrs,
    size: parseEmbedlySize(Array.from(parentDiv.classList)),
  };
};

export const parseIframeData = (parentDiv: Element, originalResultData: string) => {
  try {
    return parseEmbedlyDataResponse(parentDiv, originalResultData);
  } catch {
    return parseMalformedEmbedlyDataResponse(parentDiv, originalResultData);
  }
};

export const parseLinkCardData = (originalResultData: string, originalResultUrl: string) => {
  let resultData;
  const url = prependHttps(originalResultUrl);

  try {
    resultData = JSON.parse(originalResultData);
  } catch {
    const stringifyKeys = addQuotesToKeys(originalResultData);

    resultData = {
      description: extractValue('description', stringifyKeys),
      html: extractValue('html', stringifyKeys),
      thumbnail_url: extractValue('thumbnail_url', stringifyKeys),
      title: extractValue('title', stringifyKeys),
      type: extractValue('type', stringifyKeys),
    };
  }

  return { ...resultData, url };
};
