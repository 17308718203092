import TextStyle from '@tiptap/extension-text-style';
import { PaletteBoard } from 'saguaro';

import { DEFAULT_PALETTES } from '../../shared/constants/editor';
import { matchAndReplacePaletteColor } from '../helpers/matchAndReplacePaletteColor';

interface TextStyleOptions {
  palettes: PaletteBoard;
  HTMLAttributes: Record<string, never>;
}

export const CustomTextStyle = TextStyle.extend<TextStyleOptions>({
  name: 'textStyle',

  addOptions() {
    return {
      HTMLAttributes: {},
      palettes: DEFAULT_PALETTES,
    };
  },

  renderHTML({ HTMLAttributes }) {
    let style: string | undefined = HTMLAttributes.style;

    if (!!style) {
      style = matchAndReplacePaletteColor(this.options.palettes, style, 'color');
    }

    return ['span', { ...HTMLAttributes, style }, 0];
  },
});
