import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import IframeResizer from 'iframe-resizer-react';
import { isNumber, isString } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { EditorEmbedOptionsProvider } from '../../../../../contexts/EditorEmbedOptionsContext';
import EmbedOptionsBar from '../../components/EmbedOptionsBar/EmbedOptionsBar';
import { BaseThreeDotButtonWrapper } from '../../components/EmbedOptionsBar/ThreeDotButton';
import { embedCardOptions } from '../../lib/embedCardOptions';
import { MEDIA_SIZE_TO_WIDTH } from '../../shared/constants/editor';
import { replaceCaptureSrc } from '../../shared/helpers';
import { CustomNodeSelectedState, DragHandle } from '../../shared/styles';
import { DimensionType, IframeAttributes } from '../types';

const StyledNodeViewWrapper = styled(NodeViewWrapper)<{ $fullWidth: boolean }>`
  position: relative;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'fit-content')};
  margin: 0 auto;

  &:hover ${DragHandle} {
    display: block;
  }
`;

const Iframe = styled(IframeResizer)`
  border: 0;
  border-radius: ${({ theme }) => theme.constants.borderRadiusLg};
`;

const ThreeDotButtonWrapper = styled(BaseThreeDotButtonWrapper)`
  ${StyledNodeViewWrapper}:hover & {
    display: initial;
  }
`;

const DEFAULT_ASPECT_RATIO = 16 / 9;

const parsePercentage = (value: string, base: number): number => {
  return base * (parseInt(value) / 100);
};

const setAspectRatio = (width: DimensionType, height: DimensionType): number => {
  if (isNumber(width) && isNumber(height) && height !== 0) {
    return width / height;
  }
  return DEFAULT_ASPECT_RATIO;
};

export type Props = {
  deleteNode: NodeViewProps['deleteNode'];
  editor: NodeViewProps['editor'];
  node: { attrs: IframeAttributes };
  updateAttributes: NodeViewProps['updateAttributes'];
  selected: boolean;
};

const IframeResizeComponent = ({ deleteNode, editor, node, updateAttributes, selected }: Props) => {
  const {
    src,
    allowfullscreen,
    scrolling,
    width,
    height,
    size,
    altViewAttrs,
    isCapture,
    className,
  } = node.attrs;

  const { scaledWidth, scaledHeight, hasCustomDimensions, includeViewOption } = useMemo(() => {
    let scaledHeight: string | number | undefined;
    let scaledWidth: string | number | undefined;

    const widthIsPercentage = isString(width) && width.includes('%');
    const isPdf = src?.includes('pdf.js');
    const isEmbedly = src?.includes('embedly');

    const includeViewOption = !isPdf && !!altViewAttrs;
    const hasCustomDimensions = widthIsPercentage && !isEmbedly && !isPdf;

    if (widthIsPercentage) {
      const editorWidth = editor.view.dom.clientWidth;
      scaledWidth = parsePercentage(width, editorWidth);
      scaledHeight = scaledWidth / setAspectRatio(scaledWidth, height);
    } else {
      // Fallback to original logic if both width and height are numbers or are NOT defined
      scaledWidth = MEDIA_SIZE_TO_WIDTH[size];
      scaledHeight = scaledWidth / setAspectRatio(width, height);
    }

    return { scaledWidth, scaledHeight, hasCustomDimensions, includeViewOption };
  }, [width, height, src, altViewAttrs, editor.view.dom.clientWidth, size]);

  return (
    <StyledNodeViewWrapper $fullWidth={isCapture} draggable>
      <CustomNodeSelectedState selected={editor.isEditable && selected} stretchToFit>
        <Iframe
          allowFullScreen={allowfullscreen}
          className={`${className || ''} chromatic-ignore`}
          data-testid='iframe'
          scrolling={scrolling}
          src={isCapture ? replaceCaptureSrc(src, editor.isEditable) : src}
          style={{ width: scaledWidth, minWidth: '100%', height: scaledHeight }}
        />
      </CustomNodeSelectedState>
      <EditorEmbedOptionsProvider
        currentAttributes={node.attrs}
        deleteNode={deleteNode}
        editor={editor}
        updateAttributes={updateAttributes}
      >
        {editor.isEditable && (
          <EmbedOptionsBar
            WrapperComponent={ThreeDotButtonWrapper}
            items={embedCardOptions({
              includeViewOption,
              simpleOptions: isCapture || hasCustomDimensions,
            })}
            menuType='embed'
            triggerId={uuidv4()}
          />
        )}
      </EditorEmbedOptionsProvider>
    </StyledNodeViewWrapper>
  );
};

export default IframeResizeComponent;
