import { PaletteBoard } from 'saguaro';

import { paletteColorDecoder } from '../../../../design_system/helpers';

export const matchAndReplacePaletteColor = (
  palettes: PaletteBoard,
  styleString: string,
  colorAttribute: string
) => {
  const colorRegex = new RegExp(`${colorAttribute}:\\s*([^;]+)`);

  const match = styleString.match(colorRegex);
  if (match) {
    const originalColor = match[1].trim();
    const decodedColor = paletteColorDecoder(palettes, originalColor);

    return styleString.replace(colorRegex, `${colorAttribute}: ${decodedColor}`);
  }

  return styleString;
};
