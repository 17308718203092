import Highlight, { HighlightOptions } from '@tiptap/extension-highlight';
import { PaletteBoard } from 'saguaro';

import { DEFAULT_PALETTES } from '../../shared/constants/editor';
import { matchAndReplacePaletteColor } from '../helpers/matchAndReplacePaletteColor';

type CustomHighlightOptions = {
  palettes: PaletteBoard;
} & HighlightOptions;

export const CustomHighlight = Highlight.extend<CustomHighlightOptions>({
  name: 'highlight',

  addOptions() {
    return {
      ...this.parent?.(),
      palettes: DEFAULT_PALETTES,
      multicolor: true,
    };
  },

  renderHTML({ HTMLAttributes }) {
    let style: string | undefined = HTMLAttributes.style;

    if (!!style) {
      style = matchAndReplacePaletteColor(this.options.palettes, style, 'background-color');

      return ['mark', { ...HTMLAttributes, style }, 0];
    }

    return ['mark', { ...HTMLAttributes }, 0];
  },
});
