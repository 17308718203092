import { EmojiItem } from '@tiptap-pro/extension-emoji';
import { Editor } from '@tiptap/core';
import { ReactRenderer } from '@tiptap/react';
import tippy, { GetReferenceClientRect, Instance } from 'tippy.js';

import EmojiList, { EmojiListHandle } from './EmojiList';

interface SuggestionProps {
  editor: Editor;
  clientRect: GetReferenceClientRect;
  query: string;
}

export default {
  items: ({ editor: { storage }, query }: SuggestionProps) => {
    const emojis: EmojiItem[] = storage.emoji.emojis;
    return emojis
      .filter(({ shortcodes, tags }) => {
        return (
          shortcodes.find((shortcode) => shortcode.startsWith(query.toLowerCase())) ||
          tags.find((tag) => tag.startsWith(query.toLowerCase()))
        );
      })
      .slice(0, 10);
  },

  allowSpaces: false,

  render: () => {
    let component: ReactRenderer<EmojiListHandle, SuggestionProps>;
    let popup: Instance[];

    return {
      onStart: (props: SuggestionProps) => {
        component = new ReactRenderer(EmojiList, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate: (props: SuggestionProps) => {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown: (props: { event: KeyboardEvent }) => {
        if (props.event.key === 'Escape') {
          popup[0].hide();
          component.destroy();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit: () => {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
