import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import styled from 'styled-components';

import FileDownload from '../../components/FileDownload/FileDownload';
import { CustomNodeSelectedState } from '../../shared/styles';
import { FileAttributes } from '../types';

const SelectedState = styled(CustomNodeSelectedState)`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

const StyledNodeViewWrapper = styled(NodeViewWrapper)`
  margin: ${({ theme: { constants } }) => constants.spacerSm2};
`;

type Props = {
  editor: NodeViewProps['editor'];
  node: { attrs: FileAttributes };
  selected: boolean;
};

const FileDownloadNode = ({ node, selected, editor }: Props) => {
  const { contentUrl, fileName } = node.attrs;
  const isSelected = editor.isEditable && selected;

  return (
    <StyledNodeViewWrapper>
      <SelectedState selected={isSelected}>
        <FileDownload contentUrl={contentUrl} fileName={fileName} />
      </SelectedState>
    </StyledNodeViewWrapper>
  );
};

export default FileDownloadNode;
