import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { EmbedExtensionName } from '../types';
import VideoComponent from './VideoComponent';

export default Node.create({
  name: EmbedExtensionName.VIDEO,
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      height: {
        default: null,
        parseHTML: (element: HTMLVideoElement) => {
          return element.height;
        },
      },
      width: {
        default: null,
        parseHTML: (element: HTMLVideoElement) => {
          return element.width;
        },
      },
      alignment: {
        default: 'center',
        parseHTML: (element) => {
          const parentElement = element.parentElement;

          if (parentElement?.classList.contains('fr-fvr')) return 'right';
          if (parentElement?.classList.contains('fr-fvl')) return 'left';

          return 'center';
        },
      },
      controls: {
        default: true,
        parseHTML: (element: HTMLVideoElement) => {
          return element.controls;
        },
      },
      poster: {
        default: null,
        parseHTML: (element: HTMLVideoElement) => {
          return element.poster;
        },
      },
      src: {
        default: null,
        parseHTML: (element: HTMLVideoElement) => {
          const source = element.querySelector('source');

          if (source) return source.src;
        },
      },
      type: {
        default: null,
        parseHTML: (element: HTMLVideoElement) => {
          const source = element.querySelector('source');

          if (source) return source.type;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.fr-video video',
      },
    ];
  },

  renderHTML() {
    return ['video', { class: 'fr-video' }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(VideoComponent);
  },
});
