import { TaskList } from '@tiptap/extension-task-list';

export default TaskList.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Shift-9': () => {
        const { editor } = this;
        const isActiveList = editor.isActive('taskList');

        if (isActiveList) {
          editor.chain().focus().toggleTaskList().run();
        } else {
          editor.chain().focus().clearNodes().toggleTaskList().run();
        }

        // Returning true to indicate that the shortcut has been handled
        return true;
      },
    };
  },
});
