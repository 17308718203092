import styled from 'styled-components';

import { fontMd1, fontSm4 } from '../../../../styled/TypeSystem';

export const ErrorTitle = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd1};
`;

export const ErrorText = styled.div`
  a {
    font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
    text-decoration: none;
  }
  ${fontSm4};
`;
