import { Node } from '@tiptap/core';

export default Node.create({
  name: 'FroalaEmoticonNode',
  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      url: { default: null },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.fr-emoticon',
        priority: 100,
        getAttrs: (node) => {
          if (typeof node === 'object' && node instanceof HTMLElement) {
            const urlMatch = node.style.backgroundImage.match(/url\(["']?(.*?)["']?\)/);
            return urlMatch ? { url: urlMatch[1] } : null;
          }
          return null;
        },
      },
    ];
  },

  renderHTML({ node }) {
    return [
      'span',
      {
        class: 'emoticon',
        style: `background: url(${node.attrs.url}); display: inline-flex; width: 1.25em; height: 1.25em;`,
      },
    ];
  },
});
