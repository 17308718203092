import { embedMediaSize } from '../../shared/constants/editor';

export type EmbedSizes = 'small-embed' | 'medium-embed' | 'large-embed';

type EmbedSizeOptions = {
  [K in EmbedSizes]: embedMediaSize;
};

const embedSizeMap: EmbedSizeOptions = {
  'small-embed': 'small',
  'medium-embed': 'medium',
  'large-embed': 'large',
};

export const parseEmbedlySize = (embedlyClasses: string[]): embedMediaSize =>
  embedSizeMap[embedlyClasses.find((value) => value in embedSizeMap) as EmbedSizes] || 'medium';
