import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import styled from 'styled-components';

type AlignmentClass = 'left' | 'center' | 'right';

const StyledNodeViewWrapper = styled(NodeViewWrapper)<{ alignment: AlignmentClass }>`
  text-align: ${({ alignment }) => alignment};
`;

type Props = {
  node: {
    attrs: {
      height: string;
      width: string;
      poster: string;
      src: string;
      type: string;
      alignment: AlignmentClass;
    };
  };
};

const VideoComponent = ({ node }: Props) => {
  const { poster, src, type, height, width, alignment } = node.attrs;

  return (
    <StyledNodeViewWrapper alignment={alignment}>
      <video controls height={height} poster={poster} width={width}>
        <source src={src} type={type} />
      </video>
    </StyledNodeViewWrapper>
  );
};

export default VideoComponent;
