import ExtensionAI from '@tiptap-pro/extension-ai';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { Color } from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import { Link } from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import { TaskItem } from '@tiptap/extension-task-item';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { lowlight } from 'lowlight';
import { Dispatch, SetStateAction } from 'react';
import { PaletteBoard } from 'saguaro';

import initTranslations from '../../../../../lib/initTranslations';
import suggestion from '../../plugins/EmojiSuggestions/suggestion';
import EmbedCard from '../../tiptap_extensions/embed_card';
import FileDownload from '../../tiptap_extensions/file_download';
import EditorFileHandler from '../../tiptap_extensions/file_handler';
import FontSize from '../../tiptap_extensions/font_size';
import FontAwesomeNode from '../../tiptap_extensions/FontAwesomeNode/FontAwesomeNode';
import FroalaEmoticonNode from '../../tiptap_extensions/FroalaEmoticonNode';
import EditorHeading from '../../tiptap_extensions/heading';
import HorizontalRule from '../../tiptap_extensions/HorizontalRule/HorizontalRule';
import Iframe from '../../tiptap_extensions/iframe';
import { Indent } from '../../tiptap_extensions/indent';
import LineHeight from '../../tiptap_extensions/line_height';
import ListItem from '../../tiptap_extensions/list_item';
import Paragraph from '../../tiptap_extensions/paragraph';
import { CustomHighlight } from '../../tiptap_extensions/paragraph/highlight';
import { CustomTextStyle } from '../../tiptap_extensions/paragraph/textStyle';
import ResizableImage from '../../tiptap_extensions/resizable_image';
import { SecondaryHighlight } from '../../tiptap_extensions/SecondaryHighlight';
import { TableCell } from '../../tiptap_extensions/table/cell';
import { TableHeader } from '../../tiptap_extensions/table/header';
import { TableRow } from '../../tiptap_extensions/table/row';
import { EditorTable } from '../../tiptap_extensions/table/table';
import TaskList from '../../tiptap_extensions/task_list';
import Video from '../../tiptap_extensions/video';

const t = initTranslations('editor');

type EditableProps =
  | {
      isEditable: true;
      aiAppId: string;
      aiToken: string;
      setIsAILoading: Dispatch<SetStateAction<boolean>>;
    }
  | {
      isEditable: false;
      aiAppId?: never;
      aiToken?: never;
      setIsAILoading?: never;
    };

type EditorExtensionsProps = {
  collaborationEnabled?: boolean;
  palettes: PaletteBoard;
} & EditableProps;

export const EditorExtensions = ({
  collaborationEnabled = false,
  isEditable,
  aiAppId,
  aiToken,
  setIsAILoading,
  palettes,
}: EditorExtensionsProps) => {
  const extensions = [
    StarterKit.configure({
      codeBlock: false,
      heading: false,
      ...(collaborationEnabled && { history: false }),
      horizontalRule: false,
      listItem: false,
      paragraph: false,
    }),
    EditorFileHandler,
    ListItem,
    Indent,
    EditorHeading,
    HorizontalRule,
    Placeholder.configure({
      placeholder: t('placeholder'),
    }),
    Underline,
    EditorTable,
    TableCell.configure({
      palettes,
    }),
    TableHeader,
    TableRow,
    Paragraph,
    ResizableImage,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Iframe,
    Video,
    Link.extend({
      inclusive: false,
    }).configure({
      openOnClick: false,
    }),
    Emoji.configure({
      enableEmoticons: true,
      emojis: gitHubEmojis,
      forceFallbackImages: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      suggestion,
    }),
    FroalaEmoticonNode,
    TaskList,
    TaskItem.configure({
      nested: true,
    }),
    Subscript,
    Superscript,
    FontSize,
    CustomTextStyle.configure({
      palettes,
    }),
    FontFamily,
    LineHeight,
    FontAwesomeNode,
    CustomHighlight.configure({
      palettes,
    }),
    EmbedCard,
    FileDownload,
    CodeBlockLowlight.configure({
      lowlight,
    }),
    Color.configure({
      types: ['textStyle'],
    }),
    SecondaryHighlight,
  ];

  if (isEditable) {
    extensions.push(
      ExtensionAI.configure({
        appId: aiAppId,
        token: aiToken,
        autocompletion: false,
        onLoading: () => {
          setIsAILoading(true);
        },
        onSuccess: () => {
          setIsAILoading(false);
        },
        onError: () => {
          setIsAILoading(false);
        },
      })
    );
  } else {
    extensions.push(ExtensionAI);
  }

  return extensions;
};
