import { EmbedlyResponse } from '../../../../types/EmbedlyResponse';
import { IframeAttributes } from '../tiptap_extensions/types';

const EMBEDLY_SRC_URL_REGEX = /src=".*&url=([^&]*)&/;

type IframeEmbedlyResponseAttributes =
  | Record<string, never>
  | Pick<IframeAttributes, 'contentUrl' | 'altViewAttrs'>;

export const getIframeAttributesFromEmbedlyResponse = (
  embedlyData: EmbedlyResponse
): IframeEmbedlyResponseAttributes => {
  const { thumbnail_url: imgUrl, title, description, type } = embedlyData;

  if (type !== 'video' && type !== 'rich' && type !== 'pdf') {
    return {};
  }

  let contentUrl = type === 'video' || type === 'pdf' ? embedlyData.url : '';
  if (!contentUrl) {
    const matches = embedlyData.html.match(EMBEDLY_SRC_URL_REGEX);
    contentUrl = matches ? decodeURIComponent(matches[1]) : '';
  }
  // For converting to card view
  const altViewAttrs = {
    contentUrl,
    imgUrl,
    title,
    description,
    ...(type === 'pdf' ? { contentType: 'file' } : {}),
  };

  return { contentUrl, altViewAttrs };
};
