import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { EmbedExtensionName } from '../types';
import FileDownloadNode from './FileDownloadNode';

export default Node.create({
  name: EmbedExtensionName.FILE,
  group: 'block',
  atom: true,
  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      contentUrl: { default: null },
      fileName: { default: null },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'a.fr-file',
        priority: 100,
        getAttrs: (node) => {
          if (typeof node === 'object' && node instanceof HTMLElement) {
            const contentUrl = node.getAttribute('href');
            const fileName = node.innerText;

            return {
              contentUrl,
              fileName,
            };
          }

          return null;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      {
        ...HTMLAttributes,
        href: HTMLAttributes.contentUrl,
        class: 'fr-file',
        'data-file-name': HTMLAttributes.fileName,
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(FileDownloadNode);
  },
});
